import React, { useState } from 'react';

function FeedbackForm({ onEvent, eventId, term, apiKey }) {
    const [feedback, setFeedback] = useState('');
    const [email, setEmail] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Here you can handle the submission of the feedback.
        // You might want to send it to a server or to an email.
        console.log(feedback);
        console.log(email);
        const requestBody = JSON.stringify({ "email": email, "feedback": feedback, "event": eventId, "query": term });

        // You should replace this URL with the actual API endpoint
        const response = await fetch('https://api-dev.myride.photos/feedback', {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Specify the content type of the request body
                'Authorization': `Bearer ${apiKey}` // Attach the API key in the 'Authorization' header
            },
            body: requestBody, // Attach the request body with the search term
        }
        );
        const data = await response.json();
        onEvent(false);
    };


    return (

        <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white rounded-lg p-8">
                <form onSubmit={handleSubmit}>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Feedback</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Let us know what you think or what you are having an issue with - we are improving MyRidePhotos everyday.</p>
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                                    <div className="mt-2">
                                        <input onChange={(e) => setEmail(e.target.value)} id="email" name="email" type="email" autoComplete="email" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="feedback" className="block text-sm font-medium leading-6 text-gray-900">Feedback</label>
                                    <div className="mt-2">
                                        <textarea onChange={(e) => setFeedback(e.target.value)} id="feedback" name="feedback" rows="4" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" onClick={() => onEvent(false)} className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                        <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FeedbackForm;