import React from 'react';
import './ImageActionButtons.css';
export default function ImageActionButtons({ imageId, onInclude, onExclude, disabled }) {
  return (
            <div className="px-6 py-4  text-center flex flex-col items-center max-w-sm">
              <div className="flex flex-row gap-2 w-full">
                  <button className={` w-1/2 text-white font-bold py-2 px-4 rounded ${disabled ? 'disabled' : 'bg-blue-500 hover:bg-blue-700'}`} disabled={disabled} onClick={() => onInclude(imageId)}>This is Me</button>
                  <button className={` w-1/2 font-semibold py-2 px-4 rounded ${disabled ? 'disabled' : 'bg-transparent hover:bg-blue-500 text-blue-700  hover:text-white  border border-blue-500 hover:border-transparent'}`} disabled={disabled} onClick={() => onExclude(imageId)}>Not Me</button>
                </div>
            </div>
            )
}
