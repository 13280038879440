import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';


const CallbackPage = () => {
    const { handleRedirectCallback } = useAuth0();
  
    useEffect(() => {
      const handleAuthCallback = async () => {
        await handleRedirectCallback();
        window.location.replace(window.location.origin);
      };
  
      handleAuthCallback();
    }, [handleRedirectCallback]);
  
    return <div>Loading...</div>;
};

export default CallbackPage;
