import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import SearchBar from './SearchBar';
import ImageGallery from './ImageGallery';
import { ReactComponent as Logo } from '../logo.svg'; // Adjust the path as necessary

import Loader from './Loader'; // Import the Loader component
import EventList from './EventsList';
import useScrollDirection from '../utils';
//import { useAuth0 } from '@auth0/auth0-react';
import FeedbackForm from './FeedbackForm'; // Import the FeedbackForm component
import './SearchPage.css'
import DownloadExternal from './DownloadExternal';

function SearchPage() {
    //const { isAuthenticated } = useAuth0();
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    const handleFeedbackClick = () => {
      setShowFeedbackForm(true);
    };
    const [images, setImages] = useState([]);
    const [originalImages, setOriginalImages] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Add a loading state
    const [include, setInclude] = useState([]); // IDs of "This is Me"
    const [exclude, setExclude] = useState([]); // IDs of "Not Me"
    const [additionalImagesCount, setAdditionalImagesCount] = useState(0);
    const [newImages, setNewImages] = useState([]);

    const [vector, setVector] = useState(null); // Add a state for the vector

    const handleInclude = (id) => {
        if (!include.includes(id)) {
            setInclude([...include, id]);
        }
    };

    const handleExclude = (id) => {
        if (!exclude.includes(id)) {
            setExclude([...exclude, id]);
        }
    };
    const [event, setEvent] = useState(null);

    const apiKey = process.env.REACT_APP_API_KEY;
    const fetchAdditionalImages = async () => {
        // Placeholder for your actual API call
        const response = await fetch(`https://api-dev.myride.photos/query/vector?vector=${vector.id}&event=${event}&include=${include.join(",")}&exclude=${exclude.join(",")}&text=${term}`, {
            method: 'GET', // Adjust based on the API's requirements
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}` // Include the API key
            },
            // Include any required body or parameters according to the API's documentation
        });
        const data = await response.json();
        // Filter out IDs already known
        const newImageIds = data.filter(img => !images.some(existingImg => existingImg.id === img.id)).filter(img => img.score > 0.1);
        if (newImageIds.length > 0) {
            setAdditionalImagesCount(newImageIds.length);
            setNewImages(data.sort((a, b) => b.score - a.score).filter(img => img.score > 0.1)); // Assuming the entire image object is returned
        }
    };

    // Effect hook to call the API whenever the include or exclude lists change
    useEffect(() => {
        const getAdditional = setTimeout(() => {
            if (include.length > 0 || exclude.length > 0) {
                fetchAdditionalImages();
            }
        }, 5000);
        return () => clearTimeout(getAdditional)
    }, [include, exclude]); // Dependencies on include and exclude lists

    const searchImages = async (term) => {
        setIsLoading(true);
        const requestBody = JSON.stringify({ query: term + " riding a bicycle", event: event });

        // You should replace this URL with the actual API endpoint
        const response = await fetch('https://api-dev.myride.photos/query/text', {
            method: 'POST', // Specify the method as POST
            headers: {
                'Content-Type': 'application/json', // Specify the content type of the request body
                'Authorization': `Bearer ${apiKey}` // Attach the API key in the 'Authorization' header
            },
            body: requestBody, // Attach the request body with the search term
        }
        );
        const data = await response.json();
        setImages(data); // Assuming the response has an `images` array
        setOriginalImages(null);
        setExclude([]);
        setInclude([]);
        setIsLoading(false); // End loading

    };

    const handleImageClick = async (image) => {

        setIsLoading(true);
        setVector(image); // Save the selected image as the vector
        // Placeholder for the new API call
        const response = await fetch(`https://api-dev.myride.photos/query/vector?vector=${image.id}&event=${event}&include=&exclude=&text=${term}`, {
            method: 'GET', // Adjust based on the API's requirements
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}` // Include the API key
            },
            // Include any required body or parameters according to the API's documentation
        });
        const data = await response.json();
        setOriginalImages(images); // Save current images before displaying new ones
        setImages(data); // Assuming the API returns an `images` array
        setIsLoading(false); // End loading
        // window.history.replaceState(null, event, "/event/" + event + "/image/" + image.id);
    };

    const handleBack = () => {
        if (!!originalImages) {
            setImages(originalImages);
            setExclude([]);
            setInclude([]);
            setOriginalImages(null); // Clear the original images as we're back to the initial state
            setVector(null); // Clear the vector
            setAdditionalImagesCount(0); // Reset the count
            setNewImages([]); // Clear the new images
            setImages([])
        }
        else {
            setEvent(null);
            setImages([]);
            setTerm("")
        }
    };
    const handleViewNewImages = () => {
        setImages(newImages);
        setAdditionalImagesCount(0); // Reset the count
    };
    const eventClick = (eventId) => {
        setEvent(eventId);
        // window.history.replaceState(null, eventId, "/event/" + eventId);
        console.log(event)
    };
    const [downloading, setDownloading] = useState(false);
    const [instagram, setInstagram] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState("");
    const handleDownload = async () => {
        setDownloading(true);
        console.log("Download bundle");
        const response = await fetch(`https://api-dev.myride.photos/download?vector=${vector.id}&event=${event}&include=${include.join(",")}&exclude=${exclude.join(",")}&text=${term}`, {
            method: 'GET', // Adjust based on the API's requirements
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}` // Include the API key
            },
            // Include any required body or parameters according to the API's documentation
        }).catch((error) => { 
            console.log(error);
            setDownloading(false);
        }
        );
        const data = await response.json();
        // Check if instagram internal browser
        if (window.navigator.userAgent.includes("Instagram")) {
            setInstagram(true);
            setDownloadUrl(data.url);
        }
        else {
            window.location.href = data.url;
            setDownloading(false);
        }
        
    };

    const [term, setTerm] = useState("");
    const scrollDirection = useScrollDirection();
    return (
        <div className="App">
            <div className="fixed top-0 w-full z-10">
                <header className="App-banner text-white text-center p-6">
                    <Logo className={`logo md:w-1/2 sm:w-full mx-auto ${scrollDirection === "down" ? "hidden" : ""}`} title="MyRidePhotos" /> {/* Use your SVG like a component */}
                    <h2>Beta</h2>
                    {event !== null && !showFeedbackForm && !instagram && <SearchBar term={term} setTerm={setTerm} onSearch={searchImages} onBack={handleBack} showBack={true} />}
                </header>

                {additionalImagesCount > 0 && (
                    <div className="text-center mt-4">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleViewNewImages}>
                            View {additionalImagesCount} More Images
                        </button>
                    </div>
                )}
                {include.length > 0 && !downloading && (
                    <div className="text-center mt-4">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleDownload}>
                        Download Bundle
                    </button>
                    </div>
                )}
                {downloading && (
                    <div className="text-center mt-4">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled>
                        Downloading....
                    </button>
                    </div>
                )}
                <button
                    onClick={handleFeedbackClick}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded fixed bottom-0 right-0 m-4"
                >
                    Give Feedback
                </button>
                
            </div>
            <main className="container mx-auto mt-4 pt-60">
                {event == null && <EventList onEventClick={eventClick} />}
                {event !== null && !isLoading && images.length > 0 && !originalImages && (
                    <div>
                        <h2 className="text-center">Select the image which shows you!</h2>
                    </div>
                )}
                {isLoading ? <Loader /> :
                    event !== null && images.length > 0 &&
                    <ImageGallery
                        images={images}
                        onImageClick={originalImages ? null : handleImageClick}

                        showActionButtons={!!originalImages} // Show action buttons only for second-level images
                        onInclude={handleInclude}
                        onExclude={handleExclude}
                        exclude={exclude}
                        include={include} />}
                {term === "" && event !== null && (
                    <div>
                        <p className="text-center">Please enter a search term to get started. The more specific you are the better the results.</p>
                        <p className="text-center">i.e. <i>An orange jacket with a white helmet.</i></p>
                    </div>
                )}
                {showFeedbackForm && <div className="modal"><FeedbackForm onEvent={setShowFeedbackForm} eventId={event} term={term} apiKey={apiKey}/></div>}
                {instagram && <div className="modal"><DownloadExternal url={downloadUrl}/></div>}

            </main>
            

        </div>
    );
}
export default SearchPage;
