import React, { useState } from 'react';
import ImageActionButtons from './Gallery/ImageActionButtons';

export default function ImageGallery({ images, onImageClick, showActionButtons, onInclude, onExclude, exclude, include }) {
  const [selectedPhotographer, setSelectedPhotographer] = useState('');

  const handlePhotographerChange = (event) => {
    setSelectedPhotographer(event.target.value);
  };

  const filteredImages = selectedPhotographer ? images.filter(image => image.photographer === selectedPhotographer) : images;
  const getPhotographers = () => { 
    return images.map(image => image.photographer).filter((value, index, self) => self.indexOf(value) === index);
  };
  return (
    <div> { getPhotographers().length > 1 &&
      <div className="mt-4 w-80 m-auto">
        <label for="photographer" class="block text-sm font-medium leading-6 text-gray-900">Photographer</label>
        <select id="photographer" name="photographer" value={selectedPhotographer} onChange={handlePhotographerChange} class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <option value="">All Photographers</option>
          {getPhotographers().map((photographer, index) => (
            <option key={index} value={photographer}>{photographer}</option>
          ))}
          
        </select>
      </div> }
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-4 place-items-center ">
        {filteredImages.map((image, index) => (
          <div key={index}>
            <div className={`flex flex-col items-center max-w-sm rounded pb-2 overflow-hidden shadow-lg bg-blue-500 ${exclude.includes(image.id) ? 'opacity-50' : ''} ${include.includes(image.id) ? 'ring-4 ring-blue-500' : ''}`}>
              <div className="relative">
                <img alt="MyRidePhotos" src={image.image} className={`object-contain w-full h-full ${include.includes(image.id) ? 'opacity-100' : ''}`} onClick={() => onImageClick && onImageClick(image)} />
              </div>
              <p className="mt-2 text-white text-sm">Photograph by {image.photographer}</p>
            </div>
            {showActionButtons && ImageActionButtons({ imageId: image.id, onInclude, onExclude, disabled: exclude.includes(image.id) || include.includes(image.id) })}
          </div>
        ))}
      </div>
    </div>
  );
}
