import React from 'react';

/*const events = [
  {"id": "2024-wmf-whipoff","name":"Wicklow MTB Festival 2024 - Whip Off","date":"2024-05-03T19:00:00.000Z","time":"7:00 PM","organizer":"Wicklow MTB Festival"},
  {"id": "2024-grassroots-enduro-april","name":"Grassroots Enduro Hush 2024","date":"2024-04-28T10:00:00.000Z","time":"10:00 AM","organizer":"Hush MTB"},
  {"id": "2024_bradfield_bash","name":"Bradfield Bash 2024","date":"2024-04-21T10:00:00.000Z","time":"10:00 AM","organizer":"Yomp Bonk Crew"},
    {"id": "2024_gorey_s3","name":"Gorey 3 Day 2024 - Stage 3","date":"2024-03-31T10:00:00.000Z","time":"5:00 PM","organizer":"Derek Webb"},
    {"id": "2024_gorey_s1","name":"Gorey 3 Day 2024 - Stage 1","date":"2024-03-30T10:00:00.000Z","time":"2:00 PM","organizer":"Derek Webb"},
    {"id": "newbridgegp","name":"Life Credit Union Newbridge GP 2024","date":"2024-03-10T00:00:00.000Z","time":"12:00 PM","organizer":"Newbridge Cycling Club"},
    {"id": "navan","name":"Seamus Kennedy Memorial","date":"2024-03-03T10:00:00.000Z","time":"10:00 AM","organizer":"Navan Road Club"},
]*/
const events = [
  {"id": "2024-lincoln-gp-mens","name":"Mens Rapha Lincoln Grand Prix","date":"2024-05-12T13:00:00.000Z","time":"1:00 PM","organizer":"Rapha Lincoln Grand Prix"},
  {"id": "2024-lincoln-gp-womens","name":"Womens Rapha Lincoln Grand Prix","date":"2024-05-12T09:00:00.000Z","time":"9:00 AM","organizer":"Rapha Lincoln Grand Prix"},
  {"id": "2024-wmf-whipoff","name":"Wicklow MTB Festival 2024 - Whip Off","date":"2024-05-03T19:00:00.000Z","time":"7:00 PM","organizer":"Wicklow MTB Festival", "photographer": "@AtomCreates"},
]

const EventList = ({ onEventClick }) => {
  return (
    <div>
    <div className="mt-0 grid grid-cols-1 gap-4 mt-4 place-items-center ">
      <h2 className="text-2xl font-bold">Events</h2>
        {events.map((event, index) => (
          <div key={index} style={{ cursor: 'pointer', width: '90%' }} className="bg-white shadow-md rounded-lg p-4 flex items-center space-x-4" onClick={() => onEventClick(event.id)}>
            <div className="shrink-0 p-2 bg-blue-500 text-white rounded-lg">
              <p className="font-bold text-xl">{new Date(event.date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}</p>
              <p className="text-sm">{event.time}</p>
            </div>
            <div className="shrink">
              <p className="text-lg font-semibold">{event.name}</p>
              <p className="text-gray-600">Organized by {event.organizer}</p>
              {event.photographer && (<p className="text-gray-600">Photographer: {event.photographer}</p>)}

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventList;