import React, { useState } from 'react';

export default function SearchBar({ term, setTerm, onSearch, onBack, showBack }) {

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(term);
  };

  return (
    <div className="text-black search-bar-container">
      <form onSubmit={handleSubmit} className="grid gap-2 grid-cols-8 justify-center mt-4">
        {showBack &&
          <button onClick={onBack} className="col-span-1 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 rounded items">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mx-auto w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
          </button>}
        <input
          type="text"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          placeholder="An orange jacket with a white helmet..."
          className={`border-2 border-gray-200 p-2 rounded-lg w-1/2 ${showBack ? 'col-span-5' : 'col-span-6'} w-full`}
        />
        <button type="submit" className="col-span-2 bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 rounded">
          Search
        </button>
      </form>
    </div>
  );
}
