import React, { useState } from 'react';

function DownloadExternal({ url }) {
    
    return (
        <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white rounded-lg p-8 w-full">
                <h2 className="text-center font-bold p-2">Copy this link and open in your browser.</h2>
                <p className='text-center p-2'>It appears you're using Instagram's internal browser. To download your bundle, you need to use a normal browser. Just copy the link below and open it in your external browser.</p>
                <div className="flex flex-row items-center justify-center">
                    <button onClick={() => navigator.clipboard.writeText(url)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Copy link</button>
                </div>
            </div>
        </div>
    );
}

export default DownloadExternal;